<template>
    <div>

        <h2>Lịch sử giao dịch</h2>
        <table class="table">
            <thead>
                <th>Mã giao dịch</th>
                <th>Mệnh giá</th>
                <th>Lượng nhận</th>
                <th>Vật phẩm</th>
                <th>Trạng thái</th>
                <th>Thời gian</th>
            </thead>
            <tbody>
                <tr class="fw-semibold" v-for="trans in listTrans" :key="trans.id">
                    <td>{{ trans.id }}</td>
                    <td>{{ trans.amount }}</td>
                    <td>{{ trans.gold }}</td>
                    <td>{{ trans.items }}</td>
                    <td v-if="trans.status == 0" class="text-primary fw-bold">Đang xử lý</td>
                    <td v-else-if="trans.status == 1" class="text-success fw-bold">Thành công (Chưa nhận)</td>
                    <td v-else-if="trans.status == 2" class="text-danger fw-bold">Đã nhận</td>
                    <td>{{ trans.createdAt }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { useAuthStore } from "@/store";
export default {
    data() {
        const authStore = useAuthStore();
        const user = authStore.user;
        return {
            user,
            listTrans: []
        }
    },
    mounted() {
        fetch(`${process.env.VUE_APP_URL_ADMIN}User/GetListTransaction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username: this.user.username }),
        }).then((res) => {
            return res.json();
        }).then((data) => {
                if (data.status) {
                    this.listTrans = data.data;
                }
            });
    }
}
</script>

<style></style>