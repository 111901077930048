<template>
  <table class="table">
    <tbody>
      <tr class="fw-semibold">
        <td>Tài khoản</td>
        <td>{{user.username}}</td>
      </tr>
      <tr class="fw-semibold">
        <td>Mật khẩu</td>
        <td>*** (<a href="/changepassword">Đổi mật khẩu</a>)</td>
      </tr>
      <tr class="fw-semibold">
        <td>Email</td>
        <td>{{ user.email }}</td>
    </tr>
    <tr class="fw-semibold">
      <td>Số điện thoại</td>
      <td>{{ user.phone }}</td>
    </tr>
    <tr class="fw-semibold">
      <td>Trạng thái</td>
      <td v-if="user.online" class="text-success fw-bold">Hoạt động</td>
      <td v-else class="text-danger fw-bold">Không hoạt động</td>
    </tr>
    <tr class="fw-semibold">
      <td>Ngày tham gia</td>
      <td>{{ user.createdAt }}</td>
    </tr>
  </tbody>
</table>

</template>

<script>
import { useAuthStore } from "@/store";
export default {
  data() {
		const authStore = useAuthStore();
    const user = authStore.user;
		return {
			user
		}
	},
}
</script>

<style scoped>
.fw-semibold {
    font-weight: 600!important;
}
</style>