<template>
    <div class="d-flex justify-content-center">
        <div class="col-md-8 mt-3">
            <div class="fs-5 fw-semibold text-center">Thông tin thanh toán</div>
            <div>
                <div>
                    <div class="table-responsive-sm">
                        <table class="fw-semibold mt-3 table">
                            <tbody>
                                <tr>
                                    <td>Chủ tài khoản</td>
                                    <td>NGUYEN THAI BAO</td>
                                </tr>
                                <tr>
                                    <td>Số tài khoản</td>
                                    <td>95 36363636</td>
                                </tr>
                                <tr>
                                    <td>Ngân hàng</td>
                                    <td>Techcombank</td>
                                </tr>
                                <tr>
                                    <td>Số tiền</td>
                                    <td>{{ selectedAmount.toLocaleString("vi-VN") }} đ</td>
                                </tr>
                                <tr>
                                    <td>Nội dung</td>
                                    <td>nap {{ user.username }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center fw-semibold fs-5">Quét mã dưới</div>
                    <div class="text-center mt-2">
                        <img v-if="qrData" :src="qrData" alt="">
                    </div>
                </div>
                <div class="text-center mt-3">
                    <div class="mt-2">
                        <small class="fw-semibold">
                            <a href="/transaction">
                                Lịch sử giao dịch
                            </a>
                        </small>
                    </div>
                </div>
                <div class="mt-4">
                    <small class="fw-semibold">Lưu ý khi thanh toán:
                        Sau khi thanh toán thành công, đợi 5-10 phút hệ thống ghi nhận lịch sử giao dịch thành công sau đó
                        vào Làng Tone gặp <b>NPC Admin</b> để nhận lượng và các vật phẩm đi kèm(nếu có).
                        Nếu ghi thiếu, sai hoặc quá 10 phút không thấy lịch sử giao dịch, các bạn hãy liên hệ với Admin để
                        được hỗ trợ.
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from "@/store";
export default {
    data() {
        const authStore = useAuthStore();
        const user = authStore.user;
        return {
            user,
            qrData: null
        }
    },
    computed: {
        selectedAmount() {
            var amount = parseInt(this.$route.params.selectedAmount);
            return amount;
        },
        qrCodeUrl() {
            // Tạo URL cho mã QR sử dụng selectedAmount
            return this.qrData;
        },
    },
    mounted() {
        fetch(`${process.env.VUE_APP_URL_ADMIN}User/CreateQR?amount=${this.selectedAmount}&username=${this.user.username}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            return res.text();
        }).then((data) => {
            console.log(data);
            const qrCodeBaseUrl = 'https://api.qrserver.com/v1/create-qr-code/';
            const qrCodeParams = `size=250x250&data=${data}`;
            this.qrData = `${qrCodeBaseUrl}?${qrCodeParams}`;
        });
    }
}
</script>

<style></style>