<template>
  <div>
    <div
      class="post-item d-flex justify-content-center align-items-center my-2"
    >
      <div class="px-2">
        <a :href="getPath" class="btn btn-menu btn-danger w-100 fw-semibold">
          <svg
            class="svg-inline--fa fa-download"
            aria-hidden="true"
            focusable="false"
            data-prefix="fa-solid"
            data-icon="download"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"
            ></path>
          </svg>
          Tải xuống
        </a>
      </div>

      <div class="col-6">
        <div class="fw-bold text-danger" href="/category/2">
          {{ name }}
        </div>
        <div class="text-muted font-weight-bold">
          {{ desciption }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
defineProps(["name", "path", "desciption"]);
</script>
<script>
export default {
  data() {
    return {
      urlRoot: `${process.env.VUE_APP_URL_ROOT}`,
    };
  },
  computed: {
    getPath() {
      return `${this.urlRoot}${this.path}`;
    },
  },
};
</script>


<style scoped>
.post-item {
  background-color: #fdf8da;
  border: 1px solid #ffdbb3;
  border-radius: 16px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.051);
  padding: 10px;
}
</style>