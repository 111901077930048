import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import DefaultLayout from './layouts/DefaultLayout.vue'
import { store } from './store'
import $ from 'jquery';

window.$ = $;
window.jQuery = $;
const app = createApp(App);
app.use(store);
app.use(router);
app.component('default-layout', DefaultLayout);
app.mount('#app')