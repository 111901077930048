import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/store";
import LoginPage from "../components/LoginPage.vue";
import ProfilePage from "../components/ProfilePage.vue";
import RechargePage from "../components/RechargePage.vue";
import HomePage from "../components/HomePage.vue";
import MomoPage from "../components/MomoPage.vue";
import ATMPage from "../components/ATMPage.vue";
import MomoSelectAmount from "../components/MomoSelectAmount.vue";
import ATMSelectAmount from "../components/ATMSelectAmount.vue";
import TransactionPage from "../components/TransactionPage.vue";
import ChangePassPage from "../components/ChangePassPage.vue";
import DownLoadPage from "@/components/DownLoadPage.vue";

const routes = [
  { path: "/", component: HomePage, meta: { layout: "default" } },
  { path: "/login", component: LoginPage, meta: { layout: "default" } },
  {
    path: "/profile",
    component: ProfilePage,
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/transaction",
    component: TransactionPage,
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/recharge",
    component: RechargePage,
    meta: { layout: "default", requiresAuth: true },
    children: [
      {
        path: "",
        component: MomoSelectAmount,
      },
      {
        path: "momo",
        component: MomoSelectAmount,
        name: "momo",
      },
      {
        path: "momo/:selectedAmount",
        component: MomoPage,
        name: "momoPayment",
        props: true,
      },
      {
        path: "atm",
        component: ATMSelectAmount,
        name: "atm",
      },
      {
        path: "atm/:selectedAmount",
        component: ATMPage,
        name: "atmPayment",
        props: true,
      },
    ],
  },
  {
    path: "/changepassword",
    component: ChangePassPage,
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/file/:platform",
    component: DownLoadPage,
    meta: { layout: "default" },
    name: "download",
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Kiểm tra nếu route yêu cầu đăng nhập và user chưa đăng nhập
  const authStore = useAuthStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = authStore.user != null;
  if (requiresAuth && !isAuthenticated) {
    // Redirect về trang login
    next("/login");
  } else {
    next();
  }
});

export default router;
