<template lang="">
    <div>
		<div class="my-3 fw-semibold text-center h5">Đăng nhập</div>
		<div class="w-150 d-flex justify-content-center">
			<form @submit.prevent="handleLogin" class="pb-3" style="width: 26rem;">
				<div class="mb-2">
					<div class="input-group">
						<input v-model="username" name="username" type="text" autocomplete="off" placeholder="Tên đăng nhập" class="form-control form-control-solid">
					</div>
				</div>
				<div class="mb-2">
					<div class="input-group">
						<input v-model="password" name="password" type="password" autocomplete="off" placeholder="Mật khẩu" class="form-control form-control-solid">
					</div>
				</div>
				<div class="text-center mt-3">
					<div class="d-grid">
						<button type="submit" class="me-3 btn btn-primary">Đăng nhập</button>
					</div>
					<div class="pt-3">
						<span class="link-primary cursor-pointer">Quên mật khẩu</span>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import { useAuthStore } from "@/store";
export default {
	data() {
		return {
			username: "",
			password: "",
		};
	},
	methods: {
		handleLogin() {
			const authStore = useAuthStore();
			// Gọi action 'login' từ store và truyền thông tin đăng nhập
			authStore
				.login(this.username, this.password)
				.then((response) => {
					if (!response.status) {
						alert(response.description);
					}
					// Đăng nhập thành công, chuyển hướng người dùng đến trang dashboard
					this.$router.push("/profile");
				})
				.catch((error) => {
					// Xử lý lỗi, ví dụ hiển thị thông báo lỗi đăng nhập không thành công
					console.error(error);
				});
		},
	},
};
</script>
<style lang="">
</style>