<template>
    <div class="d-flex justify-content-center">
        <div class="col-md-8 mt-3">
            <div class="fs-5 fw-semibold text-center">Chọn mốc nạp</div>
            <div>
                <div class="row text-center justify-content-center row-cols-2 row-cols-lg-3 g-2 g-lg-2 my-1 mb-2">
                    <div v-for="amount in amounts" :key="amount">
                        <div class="col">
                            <div class="w-100 mt-2 fw-semibold cursor-pointer">
                                <div class="recharge-method-item position-relative" @click="selectAmount(amount)"
                                    :class="{ 'active': selectedAmount === amount }" v-bind:data-amount="amount"
                                    style="height: 90px;">
                                    <div>{{ amount.toLocaleString("vi-VN") }}đ</div>
                                    <div class="center-text text-danger"><span>---Nhận---</span></div>
                                    <div v-if="amount >= 20000 && amount < 50000" class="text-primary">{{ (amount * 2 + (amount * 50 / 100)).toLocaleString("vi-VN") }} lượng </div>
                                    <div v-else-if="amount >= 50000 && amount < 100000" class="text-primary">{{ (amount * 2 + (amount * 70 / 100)).toLocaleString("vi-VN") }} lượng </div>
                                    <div v-else-if="amount >= 100000 && amount < 200000" class="text-primary">{{ (amount * 2 + (amount * 90 / 100)).toLocaleString("vi-VN") }} lượng </div>
                                    <div v-else-if="amount >= 200000 && amount < 500000" class="text-primary">{{ (amount * 2 + (amount * 120 / 100)).toLocaleString("vi-VN") }} lượng </div>
                                    <div v-else-if="amount >= 500000" class="text-primary">{{ (amount * 2 + (amount * 150 / 100)).toLocaleString("vi-VN") }} lượng </div>
                                    <div v-else class="text-primary">{{ (amount * 2).toLocaleString("vi-VN") }} lượng </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="text-center mt-3">
                    <button type="button" class="w-50 rounded-3 btn btn-primary btn-sm" @click="showMomoPage()">Thanh toán</button>
                    <div class="mt-2"><small class="fw-semibold"><a href="/transaction">Lịch sử giao dịch</a></small>
                    </div>
                </div>
                <div class="mt-4">
                    <small class="fw-semibold">Lưu ý khi thanh toán: 
                        Sau khi thanh toán thành công, đợi 5-10 phút hệ thống ghi nhận lịch sử giao dịch thành công sau đó vào Làng Tone gặp <b>NPC Admin</b> để nhận lượng và các vật phẩm đi kèm(nếu có).
                        Nếu ghi thiếu, sai hoặc quá 10 phút không thấy lịch sử giao dịch, các bạn hãy liên hệ với Admin để được hỗ trợ.
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            amounts: [10000, 20000, 50000, 100000, 200000, 500000], // Các giá trị mức nạp
            selectedAmount: 10000 // Mức nạp được chọn
        };
    },
    methods: {
        selectAmount(amount) {
            this.selectedAmount = amount;
        },
        showMomoPage(){
            this.$router.push({name: 'momoPayment', params: {selectedAmount: this.selectedAmount}});
        }
    }
}
</script>

<style scoped>
</style>