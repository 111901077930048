<template>
  <div>
    <button-down-load
      v-for="pb in listPb"
      v-bind:key="pb.id"
      :name="pb.name"
      :path="pb.path"
      :desciption="pb.description"
    ></button-down-load>
  </div>
</template>
<script>
import ButtonDownLoad from "./download/ButtonDownLoad.vue";
export default {
  components: { ButtonDownLoad },
  data() {
    return {
      platform: this.$route.params.platform,
      listPb: [],
    };
  },
  created() {
    this.$watch(
      () => this.$route.params.platform,
      (newPlatform) => {
        this.getListClient(newPlatform);
      }
    );
  },
  mounted(){
    this.getListClient(this.platform);
  },
  methods:{
    getListClient(platform){
      fetch(`/config/appsettings.json`).then((res) => {
            return res.json();
        }).then((data) => {
          this.listPb = data.filter(item => item.platform === platform);
        });
    }
  }
};
</script>

<style>
</style>