<template lang="">
    <div>
		<div class="my-3 fw-semibold text-center h5">Đổi mật khẩu</div>
		<div class="w-150 d-flex justify-content-center">
			<form @submit.prevent="changePass" class="pb-3" style="width: 26rem;">
				<div class="mb-2">
					<div class="input-group">
						<input v-model="passwordold" name="password" type="password" autocomplete="off" placeholder="Mật khẩu cũ" class="form-control form-control-solid">
					</div>
				</div>
				<div class="mb-2">
					<div class="input-group">
						<input v-model="passwordnew" name="password" type="password" autocomplete="off" placeholder="Mật khẩu mới" class="form-control form-control-solid">
					</div>
				</div>
				<div class="mb-2">
					<div class="input-group">
						<input v-model="passwordconfirm" name="password" type="password" autocomplete="off" placeholder="Nhập lại mật khẩu mới" class="form-control form-control-solid">
					</div>
				</div>
				<div class="text-center mt-3">
					<div class="d-grid">
						<button type="submit" class="me-3 btn btn-primary">Đổi mật khẩu</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import { useAuthStore } from "@/store";
export default {
	data() {
		const authStore = useAuthStore();
		const user = authStore.user;
		return {
			user,
			passwordold: "",
			passwordnew: "",
			passwordconfirm: ""
		}
	},
	methods: {
		changePass() {
			fetch(`${process.env.VUE_APP_URL_ADMIN}User/ChangePassword`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					username: this.user.username,
					passwordOld: this.passwordold,
					passwordNew: this.passwordnew,
					passwordConfirm: this.passwordconfirm
				}),
			}).then((res) => {
				return res.json();
			}).then((data) => {
				alert(data.description);
			});
		}
	}
};
</script>
<style lang="">
</style>