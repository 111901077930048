<template>
  <div>
    <div class="mt-4">
      <div class="card-title h5">Đặc điểm nổi bật</div>
      <hr>
      <div class="mx-2 fs-6">Ninja school là một tựa game tuổi thơ của bao thế hệ 8x 9x, đến nay Ninja School đã hơn 10
        năm nhưng độ hot chưa từng giảm <br><br>Bạn muốn quay lại với những năm tháng tuổi thơ đó thì không cần đi đâu xa
        NSOKUDO sẽ giúp các bạn làm điều đó. Ngoài 6 môn phái cơ bản: Kunai, Kiếm, Đao ,Cung ,Tiêu ,Quạt còn có thêm các môn phái mới: Thương. 
        Với các nhân vật anh hùng nửa tránh nửa tà, có hành tung bí ẩn và các năng lực phi thường.
        
        <br><br>
        <ul>
          <li>Ookaza học sinh hệ Thủy gây đóng băng</li>
          <li>Haruna học sinh hệ Phong gây choáng váng</li>
          <li>Hirosaki học sinh hệ Hỏa gây bỏng rát</li>
        </ul>Nếu muốn làm bạn thì cùng nhau vô hang động, còn có ý phản động thì cùng nhau vào chiến trường<br><br>*Lời
        nói của ADMIN: NSOKUDO là server được mở ra dành cho anh em fangame trải nghiệm tuổi thơ, không nhằm mục đích kinh
        doanh, lôi kéo người chơi. Các bạn lưu ý kĩ trước khi trải nghiệm !
    </div>
  </div>
</div></template>

<script>
export default {

}
</script>

<style></style>