<template>
	<div id="root">
		<div class="container">
			<div class="main">
				<div class="text-center card">
					<div class="card-body">
						<a href="/">
							<div class=""><img class="logo" alt="Logo" src="@/assets/logoBT.png" style="max-height: 200px;">
							</div>
						</a>
						<div v-if="this.authStore.user != null" class="mt-3">
							<div
								class="row text-center justify-content-center row-cols-2 row-cols-lg-6 g-2 g-lg-2 my-1 mb-2">
								<button @click="$router.push('/profile')" type="button" class="btn-sd btn btn-danger mx-2">
									<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-large"
										class="svg-inline--fa fa-user-large " role="img" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512">
										<path fill="currentColor"
											d="M256 288A144 144 0 1 0 256 0a144 144 0 1 0 0 288zm-94.7 32C72.2 320 0 392.2 0 481.3c0 17 13.8 30.7 30.7 30.7H481.3c17 0 30.7-13.8 30.7-30.7C512 392.2 439.8 320 350.7 320H161.3z">
										</path>
									</svg> Tài khoản</button>
								<button @click="$router.push('/recharge')" type="button" class="btn-sd btn btn-danger mx-2">
									<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="wallet"
										class="svg-inline--fa fa-wallet " role="img" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512">
										<path fill="currentColor"
											d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64H80c-8.8 0-16-7.2-16-16s7.2-16 16-16H448c17.7 0 32-14.3 32-32s-14.3-32-32-32H64zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z">
										</path>
									</svg> Nạp lượng
								</button>
								<!-- <button @click="$router.push('/exchange')" type="button" class="btn-sd btn btn-danger mx-2">
									<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-bill"
										class="svg-inline--fa fa-money-bill " role="img" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 576 512">
										<path fill="currentColor"
											d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm64 320H64V320c35.3 0 64 28.7 64 64zM64 192V128h64c0 35.3-28.7 64-64 64zM448 384c0-35.3 28.7-64 64-64v64H448zm64-192c-35.3 0-64-28.7-64-64h64v64zM288 160a96 96 0 1 1 0 192 96 96 0 1 1 0-192z">
										</path>
									</svg> Đổi lượng
								</button> -->
							</div>
							<div
								class="row text-center justify-content-center row-cols-2 row-cols-lg-6 g-2 g-lg-2 my-1 mb-2">
								<button @click="handleLogout()" type="button" class="btn-sd btn btn-danger mx-2">
									<svg aria-hidden="true" focusable="false" data-prefix="fas"
										data-icon="arrow-right-from-bracket"
										class="svg-inline--fa fa-arrow-right-from-bracket " role="img"
										xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
										<path fill="currentColor"
											d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z">
										</path>
									</svg>
									Đăng xuất
								</button>
							</div>
						</div>
						<div v-else class="mt-3">
							<button @click="$router.push('/login')" type="button" class="btn-sd btn btn-danger mx-2">
								<svg aria-hidden="true" focusable="false" data-prefix="fas"
									data-icon="arrow-right-to-bracket" class="svg-inline--fa fa-arrow-right-to-bracket "
									role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path fill="currentColor"
										d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z">
									</path>
								</svg>
								Đăng nhập
							</button>
							<button type="button" class="ms-2 btn-sd btn btn-danger mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
								<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-plus"
									class="svg-inline--fa fa-user-plus " role="img" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 640 512">
									<path fill="currentColor"
										d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z">
									</path>
								</svg> Đăng ký
							</button>
						</div>
					</div>
				</div>
				<div>
					<div class="row text-center justify-content-center row-cols-2 row-cols-lg-6 g-2 g-lg-2 my-1 mb-2">
						<div>
							<div class="px-2"><a href="javascript:void(0)" @click="$router.push({name: 'download', params:{platform: 'java'}})"
									class="btn btn-menu btn-danger w-100 fw-semibold"><svg aria-hidden="true"
										focusable="false" data-prefix="fas" data-icon="jar" class="svg-inline--fa fa-jar "
										role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
										<path fill="currentColor"
											d="M32 32C32 14.3 46.3 0 64 0H256c17.7 0 32 14.3 32 32s-14.3 32-32 32H64C46.3 64 32 49.7 32 32zM0 160c0-35.3 28.7-64 64-64H256c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V160zm96 64c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32H224c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32H96z">
										</path>
									</svg> JAVA</a>
							</div>
						</div>
						<div>
							<div class="px-2"><a href="javascript:void(0)" @click="$router.push({name: 'download', params:{platform: 'apk'}})" class="btn btn-menu btn-danger w-100 fw-semibold"><svg
										aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-screen"
										class="svg-inline--fa fa-mobile-screen " role="img"
										xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
										<path fill="currentColor"
											d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM144 448c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H160c-8.8 0-16 7.2-16 16zM304 64H80V384H304V64z">
										</path>
									</svg> ANDROID</a>
							</div>
						</div>
						<div>
							<div class="px-2"><a href="javascript:void(0)" @click="$router.push({name: 'download', params:{platform: 'windows'}})" class="btn btn-menu btn-danger w-100 fw-semibold"><svg
										aria-hidden="true" focusable="false" data-prefix="fas" data-icon="desktop"
										class="svg-inline--fa fa-desktop " role="img" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 576 512">
										<path fill="currentColor"
											d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V288H64V64H512z">
										</path>
									</svg> WINDOWS</a>
							</div>
						</div>
						<div>
							<div class="px-2"><a href="javascript:void(0)" @click="$router.push({name: 'download', params:{platform: 'ios'}})" class="btn btn-menu btn-danger w-100 fw-semibold"><svg
										aria-hidden="true" focusable="false" data-prefix="fas" data-icon="apple-whole"
										class="svg-inline--fa fa-apple-whole " role="img" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 448 512">
										<path fill="currentColor"
											d="M224 112c-8.8 0-16-7.2-16-16V80c0-44.2 35.8-80 80-80h16c8.8 0 16 7.2 16 16V32c0 44.2-35.8 80-80 80H224zM0 288c0-76.3 35.7-160 112-160c27.3 0 59.7 10.3 82.7 19.3c18.8 7.3 39.9 7.3 58.7 0c22.9-8.9 55.4-19.3 82.7-19.3c76.3 0 112 83.7 112 160c0 128-80 224-160 224c-16.5 0-38.1-6.6-51.5-11.3c-8.1-2.8-16.9-2.8-25 0c-13.4 4.7-35 11.3-51.5 11.3C80 512 0 416 0 288z">
										</path>
									</svg> IPHONE</a>
							</div>
						</div>
					</div>
					<div class="row text-center justify-content-center row-cols-2 row-cols-lg-6 g-2 g-lg-2 my-1 mb-2">
						<div>
							<div class="px-2"><a class="btn btn-menu btn-danger w-100 fw-semibold"
									href="https://www.facebook.com/nsokudo"><svg aria-hidden="true" focusable="false"
										data-prefix="fas" data-icon="user-group" class="svg-inline--fa fa-user-group "
										role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
										<path fill="currentColor"
											d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z">
										</path>
									</svg> FanPage</a></div>
						</div>
						<div>
							<div class="px-2"><a class="btn btn-menu btn-danger w-100 fw-semibold"
									href="https://zalo.me/g/uilcct639"><svg aria-hidden="true" focusable="false"
										data-prefix="fas" data-icon="users" class="svg-inline--fa fa-users " role="img"
										xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
										<path fill="currentColor"
											d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z">
										</path>
									</svg> Box Zalo</a></div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<main>
							<slot />
						</main>
					</div>
				</div>
				<div class="text-center my-2">
					<div class="age-rule">
						<span>Chơi quá 180 phút mỗi ngày sẽ có hại cho sức khỏe</span>
					</div>
				</div>
			</div>

		</div>

		<!-- Modal -->
		<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Thông báo</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Không cần đăng ký, hãy tải game và bấm chơi mới, nhân vật của bạn đạt level 3 sẽ đủ điều kiện để xác thực tài khoản
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import ButtonDownLoad from "@/components/download/ButtonDownLoad.vue";
import { useAuthStore } from "@/store";
export default {
//   components: { ButtonDownLoad },
	data() {
		const authStore = useAuthStore();
		return {
			authStore,
			urlRoot: `${process.env.VUE_APP_URL_ROOT}`
		}
	},
	methods: {
		handleLogout() {
			// const authStore = useAuthStore();
			// Gọi action 'login' từ store và truyền thông tin đăng nhập
			this.authStore.logout();
			this.$router.push("/login");
		}
	},
	computed: {
		// Đường dẫn tới file .jar trong thư mục assets (đường dẫn tương đối)
		jarFilePath() {
			return `${this.urlRoot}download/Ninja Kudo.jar`;
		},
		jarFilePathx3() {
			return `${this.urlRoot}download/NSO Kudo x3.jar`;
		},
		jarFilePathx6() {
			return `${this.urlRoot}download/Ninja Kudo x6.jar`;
		},
		jarTB2FilePath() {
			return `${this.urlRoot}download/NSO Kudo TB2.jar`;
		},
		jarAutoTLFilePath() {
			return `${this.urlRoot}download/NSO Kudo AutoTL.jar`;
		},
		jarHSLFilePath() {
			return `${this.urlRoot}download/NSO Kudo HSL.jar`;
		},
		jar5SkillFilePath() {
			return `${this.urlRoot}download/5Skill Kudo.jar`;
		},
		apkFilePath() {
			return `${this.urlRoot}download/NSOKudo.apk`;
		},
		J2MEZipx6FilePath() {
			return `${this.urlRoot}download/NSO Kudo x6.zip`;
		},
		pcFilePath() {
			return `${this.urlRoot}download/NSO Kudo PC.rar`;
		},
		iphoneFilePath() {
			return `${this.urlRoot}download/NSO Kudo_2.1.5.ipa`;
		},
		iphone148FilePath() {
			return `${this.urlRoot}download/NSO Kudo 148.ipa`;
		}
	}
};
</script>

<style>
*,
:after,
:before {
	box-sizing: border-box;
}

.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-left: calc(var(--bs-gutter-x)*-.5);
	margin-right: calc(var(--bs-gutter-x)*-.5);
	margin-top: calc(var(--bs-gutter-y)*-1);
}

.main {
	background-color: #e7b38f;
	border-radius: 0.5rem;
	margin-top: 0.5rem;
	padding: 3px;
}

.card {
	background-color: #f9e0bb;
	margin-bottom: 2px;
	padding-bottom: 8px;
}

.age-rule {
	font-size: 12px;
	line-height: 15px;
	margin-left: 1px;
}

.btn,
.btn-menu {
	--bs-btn-padding-y: 0.35rem;
	--bs-btn-padding-x: 0.5rem;
	--bs-btn-font-size: 14.5px;
	--bs-btn-border-radius: 0.25rem;
}

.btn {
	border-bottom: 3px solid #684c40;
	border-radius: 10px;
	box-shadow: 0 5px 5px -6px rgba(0, 0, 0, .3);
	text-shadow: 1px 1px 0 #9d5331;
}

.svg-inline--fa {
	display: var(--fa-display, inline-block);
	height: 1em;
	overflow: visible;
	vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
	overflow: visible;
	box-sizing: content-box;
}

.cursor-pointer {
	cursor: pointer;
}
</style>