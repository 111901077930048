<template>
    <div>
        <div>
            <div class="fs-5 fw-semibold text-center">Chọn hình thức nạp</div>
            <div class="row text-center justify-content-center row-cols-2 row-cols-lg-5 g-2 g-lg-2 my-1 mb-2">
                <div class="col">
                    <a class="w-100 fw-semibold" @click="$router.push({name: 'momo', params: {}})">
                        <div @click="handleClick(1)" :class="{ 'active': selectedIndex === 1, 'recharge-method-item' : true }"><img alt="method" src="/images/momo.png"
                                data-pin-no-hover="true">
                        </div>
                    </a>
                </div>
                <div class="col">
                    <a class="w-100 fw-semibold" @click="$router.push({name: 'atm', params: {}})">
                        <div @click="handleClick(2)" :class="{ 'active': selectedIndex === 2, 'recharge-method-item' : true }"><img alt="method" src="/images/atm.png"
                                data-pin-no-hover="true">
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data() {
    return {
      selectedIndex: null,
    };
  },
    methods: {
    handleClick(index) {
      this.selectedIndex = index;
    },
  },
}
</script>

<style>
.recharge-method-item {
    background-color: #fdf8da;
    border: 3px solid #ffae52;
    border-radius: 16px;
    height: 100px;
    padding: 10px;
    width: 100%;
}
.recharge-method-item:hover {
    border: 3px solid #ff8400;
}

.recharge-method-item.active {
    background-color: #faeda7;
    border: 3px solid #ff5900;
}

.recharge-method-item img {
    border-radius: 16px;
    max-height: 100%;
    max-width: 100%;
}


</style>