<template>
  <!-- Sử dụng <component> :is để kiểm tra và yêu cầu VueJS:
     - Tương ứng với Component nào thì sẽ hiển thị layout tương ứng
   -->
   <component :is="layout">
     <router-view />
   </component>
</template>

<script>
import { onMounted, onUpdated, computed } from 'vue';
import router from './router'
// Khai báo layout mặc định là default
const defaultLayout = 'admin'

export default {
 setup() {
   const route = router.currentRoute; // Lấy thông tin của route hiện tại

   // Sử dụng computed để theo dõi giá trị của route.meta.layout
   const layout = computed(() => {
     // Sử dụng route.value.meta.layout để kiểm tra xem route hiện tại có khai báo meta.layout hay không
     // Nếu không có, trả về giá trị của defaultLayout
     return (route.value.meta.layout || defaultLayout) + '-layout';
   });

   // Sử dụng onMounted để thực hiện hành động khi component được mount
   onMounted(() => {
     // Thực hiện hành động khi component được mount
   });

   // Sử dụng onUpdated để thực hiện hành động khi component được cập nhật
   onUpdated(() => {
     // Thực hiện hành động khi component được cập nhật
   });
   // Trả về các giá trị và hàm cần thiết cho component
   return {
     layout,
   };
 },
 name: 'App',
}
</script>

<style>
</style>
